
import React, { Component } from 'react';
import axios from 'axios'
import { connect } from "react-redux"


const mapStateToProps = state => {
  return { 
      numOfViews: state.numOfViews,
      numOfClaps: state.numOfClaps
  }
}

const mapDispatchToProps = dispatch => {
    return { 
        setViews: (count) => dispatch({type: `SET_VIEWS`, count }),
        setClaps: (count) => dispatch({type: `SET_CLAPS`, count }),
//        increment: () => dispatch({ type: `INCREMENT_VIEWS` })
    }
}


const TTL = 12 // in hr

// Local Storage Cache to limit views
function setViewed(key, ttl) {
    key = "view_" + key
	const now = new Date()
	localStorage.setItem(key, now.getTime() + (ttl * 1000 * 60 * 60)) // Set expiry time in hr
}

// Check and remove expired item
function checkExpired(key, expiry){
    const now = new Date()
	if (now.getTime() > expiry) {
		localStorage.removeItem(key)
        console.log("Removing", key)
		return false
	}
	return true
}

function isViewed(key) {
    key = "view_" + key
	const expiry = localStorage.getItem(key)
	// if the item doesn't exist, return false
	if (!expiry) {
		return false
	}
	// check if item expired
	return checkExpired(key, expiry);
}

function clearCache() {
    //    for (var [key, value] of Object.entries(localStorage)) {
    console.log("Clearing cache")
    let keys = Object.keys(localStorage)
    for(let key of keys) {
        if(key.match(/view_.*/)){
            checkExpired(key, localStorage.getItem(key)) // delete expired key. 
        }
    }
}


class View extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //totalViews: props.views
        }
        this.props.setViews(props.views)
        console.log("[view]","==>props", props)
    }
    
    componentDidMount(){
        
        if(! isViewed(this.props.postId) ){
            console.log("Not Viewed");
            this.addView(this.props.postId);
        } else{
            console.log("Already viewed");
        }
        
        clearCache();
        
    }
    
    addView = async (postId) => {
        const postid = postId;
        //console.log(event.target);
        console.log(postid);
        //alert("addViewClicked");
        const response =
        await axios.get("/.netlify/functions/addView",
                      {params: {id: postid}}
        )
        //const response = await axios.get("/.netlify/functions/hello");
        console.log(response.status);
        if(response.status == 200){
            console.log("==>addViewClicked: ", response.data.views);
//            this.setState({
//                totalViews: response.data.views
//            })
            setViewed(this.props.postId, TTL); // set cookie ttl 
            this.props.setViews(response.data.views)
            this.props.setClaps(response.data.claps)
            return true
        }
        else{
            return false
        } 
    }

    render() {
        return (
            <div >
                <span 
                    style={{color: this.state.viewColor,
                            fontSize: '30px',
                            cursor: 'pointer'}}
                >👁️
                </span> {this.props.numOfViews}
            </div>

        );
    }
  
}

export default connect(mapStateToProps, mapDispatchToProps)(View)