import {format, distanceInWords, differenceInDays} from 'date-fns'
import React, { lazy, useEffect } from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import AuthorList from './author-list'

import styles from './blog-post.module.css'
import Clap from './clap'
import View from './view'
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios'


const getVoiceRSS = async(postId) =>{
    const response = await axios.get('/.netlify/functions/getPodcast',{
        params: {
            postId: postId
        }
    });
    
    console.log(response.status);
    
    if(response.status == 200){
        console.log("==>Response: ", response);
        return response.data
    } else {
        return false
    }
}


function BlogPost (props) {
    const {_rawBody, _id, authors, categories, claps, views, title, mainImage, publishedAt} = props
    
    const theme = useSelector(state => state.theme)
    const getThemeClass = (theme) => {
        return (theme == "light"? styles.light: styles.dark)
    }
    
    
    useEffect (() => {
        
        async function loadVoiceRSS() {
           const response = await getVoiceRSS(_id)
           document.getElementsByTagName("audio")[0].setAttribute("src",response)
        }
        
        if ( !(typeof window === 'undefined') ) {
            var audioSet = document.getElementsByTagName("audio")[0].getAttribute("src");
            console.log("audioSet==>", audioSet);
            if(!audioSet)
                loadVoiceRSS();         
        }
    })
    
  
  return (
    <article 
     id="article" className={ 
              `${styles.root} ${getThemeClass(theme)}`
          }>
      
      <div className={styles.titleContainer}>
          <h1 className={styles.title}>{title}</h1>
            {publishedAt && (
              <div className={styles.publishedAt}>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), 'MMMM Do, YYYY')}
              </div>
            )}
          <hr/>
      </div>
      
      <div className={styles.grid}>
      
        {mainImage && mainImage.asset && (
            <div className={styles.mainImage}>
              <img
                src={imageUrlFor(buildImageObj(mainImage))
                  .width(900)
                  .height(Math.floor((9 / 16) * 900))
                  .fit('crop')
                  .auto('format')
                  .url()}
                alt={mainImage.alt}
              />
            </div>
          )}
          
          <aside className={styles.metaContent}>
                
                {authors && <AuthorList items={authors} title='Authors' />}
                {categories && (
                  <div className={styles.categories}>
                    <h3 className={styles.categoriesHeadline}>Categories</h3>
                    <ul>
                      {categories.map(category => (
                        <li key={category._id}>{category.title}</li>
                      ))}
                    </ul>
                  </div>
                )}
                
                <Clap clapColor="" postId={_id} claps={claps} />
                <View postId={_id} views={views} />
              <div className={styles.audioContainer}>
                <audio 
                    className={styles.audio}
                    src=""
                    controls >
                </audio>
              </div>

          </aside>


      </div>
      

      <Container>
          <div className={styles.mainContent}>
            
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
        
        

            {authors && <AuthorList items={authors} title='Authors' />}
            <Clap clapColor="" postId={_id} claps={claps} />


      </Container>
    </article>

  )
}

export default BlogPost
