
import React, { Component } from 'react';
import axios from 'axios'
import { connect } from "react-redux"

const mapStateToProps = state => {
  return { 
      numOfViews: state.numOfViews,
      numOfClaps: state.numOfClaps,
      colorOfClap: state.colorOfClap
  }
}

const mapDispatchToProps = dispatch => {
    return { 
        setViews: (count) => dispatch({type: `SET_VIEWS`, count }),
        setClaps: (count) => dispatch({type: `SET_CLAPS`, count }),
        increment: () => dispatch({ type: `INCREMENT_VIEWS` }),
        setColor: (color) => dispatch({ type: `SET_COLOR`, color })
    }
}


class Clap extends Component {

    constructor(props) {
        super(props);
//        this.state = {
//            clapColor: props.clapColor, //Set it to redux (limiting scope to particular component)
//        }
        this.props.setClaps(props.claps)
        console.log("==>props", props)
    }
    
    componentDidMount(){
        if(this.isClapped(this.props.postId)){
            this.colorClap(true);
        }  
    }
    
    addClap = async (postId) => {
        const postid = postId;
        //console.log(event.target);
        console.log(postid);
        //alert("addClapClicked");
        const response =
        await axios.get("/.netlify/functions/addClap",
                      {params: {id: postid}}
        )
        //const response = await axios.get("/.netlify/functions/hello");
        console.log(response.status);
        if(response.status == 200){
            console.log("==>addClapClicked: ", response.data.claps);
            this.props.setClaps(response.data.claps)
            this.props.setViews(response.data.views)
            return true
        }
        else{
            return false
        } 
    }
    

    colorClap = (doColor) => {
//        this.setState({
//          clapColor: doColor? "blue": this.props.clapColor
//        })
        this.props.setColor( doColor ? "blue": this.props.clapColor )
        
    }

    isClapped = (postId) => {
        if(localStorage.getItem("clappedBlogs")){
            var clappedBlogs = JSON.parse(localStorage.getItem("clappedBlogs"));
            if(clappedBlogs.includes(postId)){
                return true;
            }
            return false;
        }
    }


    
    handleClapEvent = async (event) => {
        const _id = this.props.postId;
        var fn = "[handleClapEvent]";
        
        console.log(fn, _id);
        this.colorClap(true);
        
        if(localStorage.getItem("clappedBlogs")){
            var clappedBlogs = JSON.parse(localStorage.getItem("clappedBlogs"));
            if(clappedBlogs.includes(_id)){
                console.log(fn, "Present");
                alert("Already clapped.");
            }else{
                console.log(fn, "awaiting addClapDB");
                var isClapSuccess = await this.addClap(_id);
                console.log(fn, "returned from addClapDB", isClapSuccess);
                if(isClapSuccess){
                    clappedBlogs.push(_id);
                    localStorage.setItem("clappedBlogs", JSON.stringify(clappedBlogs));
                    console.log(fn, "not present");      
                }else{
                    this.colorClap(false);
                    alert("Clap failed");
                }

            }
        }else{
            console.log(fn, "no cache found"); 
            console.log(fn, "awaiting addClapDB");
            var isClapSuccess = await this.addClap(_id);
            console.log(fn, "returned from addClapDB", isClapSuccess);
            if(isClapSuccess){
                var arr = [_id];
                localStorage.setItem("clappedBlogs", JSON.stringify(arr));             
            }else{
                this.colorClap(false);
                alert("Clap failed");
            }

        }  
    }


    render() {
        return (
            <div className="App">
                <span 
                    style={{color: this.props.colorOfClap,
                            fontSize: '30px',
                            cursor: 'pointer'}}
                    onClick={this.handleClapEvent.bind(this)} 
                    >👏
                </span> {this.props.numOfClaps}
            </div>
        );
    }
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Clap)